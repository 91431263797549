import React, { useState, useEffect } from "react";
import Sparkle from "react-sparkle";

import Logo from "./assets/images/logo_adgf_final.png";

import Sapin from "./assets/images/sapin.png";

import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";

const App = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isAnimated, SetIsAnimated] = useState(false);

  const timer = () => {
    const now = new Date();
    const end = new Date("2024-01-07T23:59:59");
    const diff = end - now;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60)) % 24;
    const minutes = Math.floor(diff / (1000 * 60)) % 60;
    const seconds = Math.floor(diff / 1000) % 60;

    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  };

  useEffect(() => {
    timer();
    setInterval(timer, 1000);
    SetIsAnimated(!isAnimated);
  }, []);

  return (
    <div className={"position-relative "}>
      <div className={"about-us-main-image App pb-0 mb-0 "}>
        <Sparkle
          miWebsitenSize={10}
          maxSize={30}
          overflowPx={50}
          color={"#FFC700"}
        />
        <div className={"row"}>
          <div className={"col-md-8 "}>
            <img
              className={"mx-auto d-block py-5"}
              src={Logo}
              alt="img-1"
              width={200}
            />
            <div className={"first-title mx-5 text-center   mx-auto "}>
              <p className={" "}>Plateforme en construction</p>
              <p className={""}>Disponible à partir du Lundi 08 Janvier 2024</p>
            </div>
            <div className="countdown">
              <div className={"row"}>
                <div className="col-3">
                  <div className="box">
                    <p className="day">{days}</p>
                    <span className="text">Jrs</span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="box">
                    <p className="hour">{hours}</p>
                    <span className="text">Hrs.</span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="box">
                    <p className="minute">{minutes}</p>
                    <span className="text">Mins.</span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="box">
                    <p className="second">{seconds}</p>
                    <span className="text">Secs.</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                "row  mx-3 mx-md-auto col-md-8 pt-5 d-flex  justify-content-center align-items-center"
              }
            >
              <div className={"first-title text-center col-6 col-md-12"}>
                <marquee
                  className={"d-md-none"}
                  behavior="scroll"
                  direction="up"
                  width="100%"
                  height="100"
                  scrollamount="2"
                  scrolldelay="15"
                >
                  <div
                    style={{
                      fontFamily: "Papyrus",
                      fontStyle: "italic",
                      fontWeight: "900",
                    }}
                  >
                    L'ADGF
                  </div>
                  <div
                    className={"mt-3"}
                    style={{
                      fontFamily: "Papyrus",
                      fontStyle: "italic",
                      fontWeight: "900",
                    }}
                  >
                    et tout le groupement FOMOPEA
                  </div>
                  <div
                    className={"mt-3"}
                    style={{
                      fontFamily: "Papyrus",
                      fontStyle: "italic",
                      fontWeight: "900",
                    }}
                  >
                    vous souhaitent de passer
                  </div>
                  <div
                    className={"mt-3"}
                    style={{
                      fontFamily: "Papyrus",
                      fontStyle: "italic",
                      fontWeight: "900",
                    }}
                  >
                    d'excellentes fêtes de fin d'années:
                  </div>
                  <div
                    className={"mt-3"}
                    style={{
                      fontFamily: "Papyrus",
                      fontStyle: "italic",
                      fontWeight: "900",
                    }}
                  >
                    Joyeux Noel
                  </div>
                  <div
                    className={"mt-3"}
                    style={{
                      fontFamily: "Papyrus",
                      fontStyle: "italic",
                      fontWeight: "900",
                    }}
                  >
                    et Bonne année 2024!!
                  </div>
                </marquee>
                <marquee
                  className={"d-none d-md-block"}
                  behavior="scroll"
                  direction="left"
                  width="100%"
                  height="100"
                  scrollamount="2"
                  scrolldelay="75"
                >
                  <div
                    style={{
                      fontFamily: "Papyrus",
                      fontStyle: "italic",
                      fontWeight: "900",
                    }}
                  >
                    L'ADGF et tout le groupement FOMOPEA vous souhaitent de
                    passer d'excellentes fêtes de fin d'années: Joyeux Noel et
                    Bonne année 2024!!
                  </div>
                </marquee>
              </div>
              <div className={"mx-auto d-md-none  col-6 rotated"}>
                <img src={Sapin} alt="img-1" height={300} />
              </div>
            </div>

            <div
              className={
                "first-title text-center col-10 col-md-8 mx-auto  border border-success  radius6 row p-5"
              }
            >
              <div className={"col-12 d-flex d-flex pb-3  "}>
                <PhoneAndroidIcon sx={{ color: "#ffffff" }} />
                <WhatsAppIcon sx={{ color: "#ffffff" }} />
                <p className={"mb-0 ps-3 ps-md-5 "}>{"+237 6 71 44 31 17"}</p>
              </div>
              <div className={"col-12 d-flex d-flex  "}>
                <EmailIcon sx={{ color: "#ffffff" }} />
                <p className={"mb-0 ms-4 ps-3 ps-md-5 "}>
                  {"info@adgfomopea.org"}
                </p>
              </div>
            </div>
          </div>
          <div className={"col-2 d-none d-md-block "}>
            <div className={"rotated"}>
              <img src={Sapin} alt="img-1" height={700} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
